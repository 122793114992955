import React from 'react'
import { Grid } from '@mui/material'

import t1 from './MOOTH.jpeg'

import biz from './smooth.png'


function Desktop() {
  return (
    <div className="bg">
    <div className="pageCon"><br/><br/>
 <h1 className='maintext'>
  
 <img src={t1} className='topimg'/>
  
  </h1> <br/>
        
        <Grid container spacing={1}>
         
        <Grid item md={12} lg={12} xs={12} sm={12}>
        <div className='centeritall'>
       <a href='https://twitter.com/MOOTHfun'>
       <button class="btnf">Twitter</button>
       </a>&nbsp;&nbsp;
       <a href='https://t.me/MOOTHfun'>
       <button class="btnf">Telegram</button>
       </a>

       <a href='https://dexscreener.com/solana/87uqenqwvk5uakcaasydy15elhemo4k4sbmtlbfjadxb'>&nbsp;&nbsp;
       <button class="btnf">Chart</button>
       </a>&nbsp;&nbsp;
       <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=GfkUuTCT8xva5BVEempTwaa6PNrVtt8sLLsv1A6THsKM&fixed=in'>
       <button class="btnf">Buy</button>
       </a>
         </div>  <br/> <br/>
         <p style={{textAlign:"center"}}> CA : GfkUuTCT8xva5BVEempTwaa6PNrVtt8sLLsv1A6THsKM</p>
         
        </Grid>
        
       
        </Grid>
        
      
         <br/>
  </div>  
  
  
  
  
  
  </div>
  )
}

export default Desktop