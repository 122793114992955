import React from 'react'
import { Grid } from '@mui/material'
import biz1 from './o1.jpeg'
import biz2 from './o2.jpeg'
import biz3 from './o3.jpeg'
import biz4 from './o4.jpeg'
import biz5 from './o5.jpeg'
import biz6 from './o6.jpeg'



function Footer() {
  return (
    <div className='coloroffooter'>
<div className='container'>
<br/><br/>
<Grid container spacing={2}>
<Grid item md={6} xs={6} sm={6} lg={6}>
<img src={biz1} style={{width:"100%" , borderRadius:"30px"}} />
</Grid>
<Grid item md={6} xs={6} sm={6} lg={6}>
<img src={biz2} style={{width:"100%" , borderRadius:"30px"}} />
</Grid>
<Grid item md={6} xs={6} sm={6} lg={6}>
<img src={biz3} style={{width:"100%" , borderRadius:"30px"}} />
</Grid>
<Grid item md={6} xs={6} sm={6} lg={6}>
<img src={biz4} style={{width:"100%" , borderRadius:"30px"}} />
</Grid>
<Grid item md={6} xs={6} sm={6} lg={6}>
<img src={biz5} style={{width:"100%" , borderRadius:"30px"}} />
</Grid>
<Grid item md={6} xs={6} sm={6} lg={6}>
<img src={biz6} style={{width:"100%" , borderRadius:"30px"}} />
</Grid>
</Grid>



<br/>

</div>

    </div>
  )
}

export default Footer