import React from 'react'
import ab from './abomoo.png'
import { Grid } from '@mui/material'
function Desktopthree() {
  return (
    <div className='backofabou'>
 
      <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
      <div className='container'>
      <Grid container spacing={1}>
         
         <Grid item md={6} lg={6} xs={12} sm={12}>
       
          <img src={ab} />
         </Grid>
         <Grid item md={6} lg={6} xs={12} sm={12}>
          <br/> <br/>
     <h2 style={{color:"#fff", fontSize:"30px"}} className='a1'> About</h2>
     <br/>
     <p className='a1p'> MOOTH IS RETARDED </p>
     <br/>
     <h2 style={{color:"#fff", fontSize:"30px"}} className='a1'> WHY $MOOTH</h2>
     <br/>
     <p className='a1p'> MOOTH IS RETARDED</p>
      </Grid>
      <Grid item md={12} lg={12} xs={12} sm={12}>
          <br/> <br/> <br/>    <h2 style={{color:"#fff", fontSize:"30px"}} className='a1'> HOW TO BUY</h2><br/>
     <h2 style={{color:"#fff", fontSize:"30px"}} className='a1'>I Download Phantom</h2>
     <br/>
     <p className='a1p'> 
<br/>
Go to chrome webstore and get phantom ​addon </p>
     <br/>
     <h2 style={{color:"#fff", fontSize:"30px"}} className='a1'>II Add funds</h2>
     <br/>
     <p className='a1p'>
     <br/>
Buy solana on an exchange and send sol ​to your phantom wallet</p>
<br/>
     <h2 style={{color:"#fff", fontSize:"30px"}} className='a1'>II Add funds</h2>
     <br/>
     <p className='a1p'>
     <br/>
Buy solana on an exchange and send sol ​to your phantom wallet</p>
      </Grid>
         </Grid>


      </div>
      <br/> <br/> 
      </div>
  )
}

export default Desktopthree